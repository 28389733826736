import './ApptInfo.css'
import { useState } from 'react'

function ApptInfo() {
  const [service, setService] = useState(1)

  function isActive(num) {
    return (service === num) ? 'active' : 'inactive'
  }


  return (
    <div className="appt-container">
      {/* Header */}
      {/* <h1>Appointment Information</h1> */}
      {/* Content */}
      <div className='appt-content-container'>
        <div className='appt-tabs-container'>
          <p className='appt-tabs'
            onClick={(e) => setService(1)}
            id={isActive(1)}
          >
            New Patient Visit
          </p>
          <p className='appt-tabs'
            id={isActive(2)}
            onClick={(e) => setService(2)}>
            Follow-Up Visit
          </p>
          <p className='appt-tabs'
            id={isActive(3)}
            onClick={(e) => setService(3)}>
            Food Challenge Visit
          </p>
          <p className='appt-tabs'
            id={isActive(4)}
            onClick={(e) => setService(4)}>
            Oral Immunotherapy (OIT) Visit
          </p>
          <p className='appt-tabs'
            id={isActive(5)}
            onClick={(e) => setService(5)}>
            Sublingual Immunotherapy (SLIT) Visit
          </p>
          <p className='appt-tabs'
            id={isActive(6)}
            onClick={(e) => setService(6)}>
            Allergy Shot Visit
          </p>
          <p className='appt-tabs'
            id={isActive(7)}
            onClick={(e) => setService(7)}>
            Drug Challenge Visit
          </p>
        </div>

        <div className='appt-detail-container'>

          <div className='appt-subservices'>
            <div className='appt-new' id={isActive(1)}>
              <div className='detail-container'>
                <h2>New Patient Visit</h2>

                <ul>
                  <li> You will have a consultation with a provider to determine appropriate testing.  </li>
                  <li>For allergy skin testing, do <u>not</u> take long-acting antihistamines<strong>*</strong> for 5 days before the visit.  If you are taking one of the medications listed for a non-allergy related medical problem, continue the medication as directed by your prescriber.  If you are unable to stop antihistamines because you need it for allergy symptom relief, other ways of testing will be discussed at your visit.  If you are not sure if you should stop taking a medication, please call us.</li>
                  <li>If you have been prescribed or are using any inhalers, please bring them to the visit, including your spacer if you have one.</li>
                </ul>

                <h5 className='appt-antilist-header'>*Long-Acting Antihistamines to stop 5 days before skin testing:</h5>
                <ul id='appt-antilist'>
                  <li>
                  Zyrtec (cetirizine)
                  </li>
                  <li>
                    Claritin (loratadine)
                  </li>
                  <li>
                    Allegra (fexofenadine)
                  </li>
                  <li>
                    Astepro (azelastine)
                  </li>
                  <li>
                    Atarax (hydroxyzine)
                  </li>
                </ul>
                
              </div>
            </div>

            <div className='appt-followup' id={isActive(2)}>
              <div className='detail-container'>
                <h2>Follow-Up Visit</h2>
                <ul>
                  <li>
                    Follow-Up Visits are required every 3-6 months for patients being treated with a biologic medication (Xolair, Dupixent, Fasenra, Nucala).  Visits are required every 6-9 months for patients with asthma who are managed on inhaler medications.  Visits are required once a year for patients on allergy shots or who have food allergies.
                  </li>
                  <li>
                    Allergy skin testing may be completed, if appropriate.  If you are expecting skin testing, do <u>not</u> take long-acting antihistamines* for 5 days before the visit.  If you are taking one of the medications listed for a non-allergy related medical problem, continue the medication as directed by your prescriber.  If you are unable to stop antihistamines because you need it for allergy symptom relief, other ways of testing will be discussed at your visit.  If you are not sure if you should stop taking a medication, please call us.
                  </li>
                  <li>
                    If you have been prescribed or are using any inhalers, please bring them to the visit, including your spacer, if you have one.  
                  </li>
                </ul>
                <h5 className='appt-antilist-header'>*Long-Acting Antihistamines to stop 5 days before skin testing:</h5>
                <ul id='appt-antilist'>
                  <li>
                    Zyrtec (cetirizine)
                  </li>
                  <li>
                    Claritin (loratadine)
                  </li>
                  <li>
                    Allegra (fexofenadine)
                  </li>
                  <li>
                    Astepro (azelastine)
                  </li>
                  <li>
                    Atarax (hydroxyzine)
                  </li>
                </ul>
              </div>
            </div>

            <div className='appt-food' id={isActive(3)}>
              <div className='detail-container'>
                <h2>Food Challenge Visit</h2>
                <ul>
                  <li>
                    Food challenges may be scheduled <u>only</u> if pre-approved by your provider.
                  </li>
                  <li>
                    Expect to be in the office for about 3 hours.
                  </li>
                  <li>
                    You are required to bring the food that you are challenging.
                  </li>
                  <li>
                    You should eat a light breakfast or lunch, depending on when your appointment is scheduled.
                  </li>
                  <li>
                    You are welcome to bring in extra snacks and a drink.
                  </li>
                  <li>
                    Bring your epinephrine injector to the visit.
                  </li>
                  <li>
                    You do <u>not</u> need to stop antihistamines before the food challenge visit.
                  </li>
                  <li>
                    You must be healthy the day of the Food Challenge.  Please reschedule if there are any signs of illness (nasal congestion, runny nose, coughing, wheezing, shortness of breath, nausea).
                  </li>
                </ul>
              </div>
            </div>
            <div className='appt-oit' id={isActive(4)}>
              <div className='detail-container'>
                <h2>Oral Immunotherapy (OIT) Initial Escalation Visit</h2>
                <ul>
                  <li>The OIT Initial Escalation visit is the first visit to start OIT.</li>
                  <li>An OIT Initial Escalation visit may be scheduled <u>only after</u> you have had a clinical consult with either Anny Uyehara, CRNP or Caroline Wettengel, DNP <u>and</u> a financial consult with Sophie Quinnam, Practice Manager.</li>
                  <li>Expect to be in the office for about 3 hours.</li>
                  <li>You should eat a light breakfast or lunch, depending on when your appointment is scheduled.</li>
                  <li>You are welcome to bring in extra snacks and a drink.</li>
                  <li>Bring your epinephrine injector to the visit.</li>
                  <li>
                    You must be healthy the day of the Initial Escalation Visit.  Please reschedule if there are any signs of illness (nasal congestion, runny nose, coughing, wheezing, shortness of breath, nausea).
                  </li>
                </ul>
              </div>
              <div className='detail-container'>
                <h2>Oral Immunotherapy (OIT) Up-Dose Visit</h2>
                <ul>

                  <li>
                    OIT Up-Dose Visits are only scheduled after you have started OIT.  The frequency of visits follow the dosing schedule that you and your provider reviewed.
                  </li>
                </ul>
              </div>
            </div>
            <div className='appt-slit' id={isActive(5)}>
              <div className='detail-container'>
                <h2>Sublingual Immunotherapy (SLIT) Visit</h2>
                <ul>
                  <li>SLIT Visits vary depending on where you are in the course of treatment.</li>
                  <li><u>Take</u> an antihistamine 1 hour prior to your visit.</li>

                  <li>Expect to be in the office for about 1 hour.</li>

                  <li>Bring your epinephrine injector to the visit.</li>

                  <li>To start SLIT, you are required to have 3 visits, one week apart.</li>
                  <ul>
                    <li>
                      <strong>SLIT Visit #1:</strong> you will start the least concentrated vial, called Vial #3.  Staff will observe you for 30 minutes after the final dose in the office.  You will leave with Vial #3 to take every day at home until you return for the next visit.
                    </li>
                    <li>
                      <strong>SLIT Visit #2:</strong> you will start the next concentrated vial, called Vial #2.  Staff will observe you for 30 minutes after the final dose in the office.  You will leave with Vial #2 to take every day at home until you return for the next visit.
                    </li>
                    <li>
                      <strong>SLIT Visit #3:</strong> you will start the most concentrated vial, called Vial #1.  You will leave with Vial #1 to take every day at home, which has enough doses for 1 month.  You are required to request a refill at least 2 weeks before the vial is expected to run out.  You may request the refill as early as the SLIT Visit #1 if you would like to pick up additional vials at SLIT Visit #3.
                    </li>
                  </ul>
                  <li>If you are treating seasonally with Spring SLIT and/or Fall SLIT, you will initially start as indicated above. </li>
                </ul>
              </div>
              <div className='detail-container'>
                <h2>SLIT Restart Visit</h2>
                <ul>
                  <li>
                    SLIT Restart Visit is for patients who have been treated with Seasonal SLIT (Spring or Fall), at least the year before
                  </li>
                  <li>
                    Spring SLIT Restart Visit occurs in December to restart the treatment in preparation for spring (for example, December 2025 SLIT Restart visit to restart treatment for Spring 2026).  Spring SLIT treatment usually continue through June.
                  </li>
                  <li>
                    Fall SLIT Restart Visit occurs sometime between May-June, depending on when you and your provider have decided, and continues through November.
                  </li>
                  <li>
                    SLIT Restart visit is <u>not</u> a follow-up visit with your provider.  You are required to have a <u>separate follow-up</u> visit with your provider in June for Spring SLIT treatment to evaluate symptoms on treatment during the spring season.  If you are being treated with Fall SLIT, you should have a follow up in December to evaluate symptoms on treatment during the fall season.
                  </li>
                </ul>
              </div>
            </div>
            <div className='appt-allergy' id={isActive(6)}>
              <div className='detail-container'>
                <h2>Allergy Shot Visit</h2>
                <ul>
                  <li>
                    All allergy shots are given by appointment only.
                  </li>
                  <li>
                    If you are more than 10 minutes late for your appointment, you may be asked to reschedule.
                  </li>
                  <li>
                    All allergy shot patients are <u>required to be observed for 30 minutes in the office after every shot</u> due to the risk of an allergic reaction.  If you are unable to stay for 30 minutes after your shot, please reschedule.  Failing to adhere to this policy may result in discontinuation of treatment due to safety.
                  </li>
                </ul>
              </div>
            </div>

            <div className='appt-drug' id={isActive(7)}>
              <div className='detail-container'>
                <h2>Drug Challenge Visit</h2>
                <ul>
                  <li>
                    Drug Challenge Visits may be scheduled <u>only</u> if pre-approved by your provider.
                  </li>
                  <li>
                    Expect to be in the office for about 1.5 hours, unless otherwise stated by your provider.
                  </li>
                  <li>
                    You may eat and drink per usual before the visit.
                  </li>
                  <li>
                    You do not need to stop antihistamines before the Drug Challenge Visit.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ApptInfo