import Message from "../message/Message"
import Navbar from "../navbar/Navbar"
import './Header.css'

function Header({ showMessage }) {

  return (
    <div className="header-container">
      <Navbar />
      {showMessage && <Message />} {/*Conditionally renders the message component*/}
    </div>
  )

}
export default Header