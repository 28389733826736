import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { authenticate } from '../../store/session';
import { useNavigate } from 'react-router-dom'

import CreateMessageForm from './CreateMessageForm';
import ProviderList from './ProviderList';
import './AdminPortal.css'

function AdminPortal({showMessage, setShowMessage}) {
  const [messageInput, setMessageInput] = useState(showMessage ? 'true' : 'false');
  const [currentUserIsLoaded, setCurrentUserIsLoaded] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentUser = useSelector(state => state.session.user);

  useEffect(() => {
    (async () => {
      await dispatch(authenticate())
      setCurrentUserIsLoaded(true);
    })();
  }, [dispatch])
  if (!currentUser){
    navigate('/adminlogin')
  }
  if (currentUserIsLoaded === false) return null

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedValue = messageInput === 'true';

    // Update the state in App.js
    setShowMessage(updatedValue);

    // Save the value to the backend
    const response = await fetch('/api/messages/show-message', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ display: updatedValue }),
    });

    if (!response.ok) {
      console.error('Failed to update showMessage');
    }
  };

  return (
    <div className='adminportal-container'>
      <h1 style={{color: "var(--black)", fontWeight: 400, fontSize: "36px", paddingTop: "40px"}}>Admin Portal for Allergy & Asthma of Central Maryland</h1>
      
      <div className='divider'></div>
      <h3 className='edit-headers'>Message Banner</h3>
      
      <div className='divider'></div>
      <div className='showmessage-container'>
        <p className='createmessage-header'>Message Banner Visibility</p>

        <form onSubmit={handleSubmit}>
          <label className='showmessage-label'>
            <input
              type="radio"
              name="message"
              value="true"
              // checked={showMessage === true}
              onChange={(e) => setMessageInput(e.target.value)}
            />
            Show
          </label>
          <label className='showmessage-label'>
            <input
              type="radio"
              name="message"
              value="false"
              // checked={showMessage === false}
              onChange={(e) => setMessageInput(e.target.value)}
            />
            Hide
          </label>
          <br />
          <br />

          <button type="submit" className='showmessage-button'>Update</button>
        </form>
      </div>
      

      <CreateMessageForm />
      <div className='divider'></div>

      <h3 className='edit-headers'>Edit Providers</h3>
      <div className='divider'></div>

      <ProviderList />
    </div>
  )

}
export default AdminPortal