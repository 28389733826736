import { NavLink } from 'react-router-dom';
import './Navbar.css'
import { useLocation } from 'react-router-dom';

function Navbar() {
  const location = useLocation()

  function isCurrent(path){
    return (path === location.pathname) ? 'active' : 'inactive'
  }

  return (
    <div className='navbar-container'>
      {/* Primary Navbar */}
      <div className='navbar-top-container'>
        <a href="/" className='navbar-logo-container'>
          <img src="/static/images/aacm_logo.png" alt="logo" className='navbar-logo' />
        </a>
        <div className='navbar-top-link-container'>
          <NavLink to='https://10561.portal.athenahealth.com/' target="_blank" className='navbar-links' id='top'>Patient Portal</NavLink>
          <NavLink to='/contact' className='navbar-links' id='top'>Contact & Hours</NavLink>
        </div>
      </div>
      {/* Secondary Navbar */}
      <div className='navbar-bottom-container'>
        <NavLink to='/ourpractice' className='navbar-links' id={isCurrent("/ourpractice")}>Our Practice</NavLink>
        <NavLink to='/services' className='navbar-links' id={isCurrent("/services")}>Services</NavLink>
        <NavLink to='/telemedicine' className='navbar-links' id={isCurrent("/telemedicine")}>Telemedicine Visits</NavLink>
        <NavLink to='/apptinfo' className='navbar-links' id={isCurrent("/apptinfo")}>Appointment Information</NavLink>
        <NavLink to='/forms' className='navbar-links' id={isCurrent("/forms")}>School Forms</NavLink>
      </div>
    </div>
  )
}
export default Navbar